import clsx from 'clsx';
import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus, autoFocus = false }) => (
    <form className={clsx(className, 'relative')}>
      <input
        className="block w-full p-3 pr-5 border-2 rounded-xl border-primary-200 focus:border-secondary-200 outline-none"
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={(e) => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 absolute top-1/2 right-0 transform -translate-x-4 -translate-y-1/2 pointer-events-none"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clipRule="evenodd"
        />
      </svg>
    </form>
  )
);
