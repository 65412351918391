import clsx from 'clsx';
import { navigate } from 'gatsby';
import React, { useState } from 'react';

import './navigate-to-search.scss';

const NavigateToSearch = ({ className, autoFocus = false }) => {
  const [value, setValue] = useState('');

  function handleSearch(e) {
    e.preventDefault();

    navigate(`/search/?query=${value}`);
  }

  return (
    <div
      className={clsx(
        className,
        'NavigateToSearch relative flex flex-row text-xl'
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 absolute top-1/2 left-0 transform translate-x-4 -translate-y-1/2 pointer-events-none"
        viewBox="0 0 20 20"
        fill="currentColor"
        style={{ width: '1.24rem' }}
      >
        <path
          fillRule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clipRule="evenodd"
        />
      </svg>
      <input
        className="block w-full p-3 pl-12 border-2 rounded-xl border-primary-200 focus:border-secondary-200 border-r-0 rounded-r-none outline-none"
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        autoFocus={autoFocus}
        onKeyPress={(e) => e.key === 'Enter' && handleSearch(e)}
      />
      <a
        href="/search/"
        onClick={handleSearch}
        className="btn btn-secondary text-center flex items-center"
      >
        Search
      </a>
    </div>
  );
};

export default NavigateToSearch;
