import clsx from 'clsx';
import { Link, navigate } from 'gatsby';
import React from 'react';
import {
  connectStateResults,
  connectHitInsights,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from 'react-instantsearch-dom';
import aa from 'search-insights';

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null;
});

const PageHit = ({ hit, insights }) => (
  <Link
    to={hit.path}
    className={`
      block border-2 rounded-xl border-secondary-300 hover:bg-primary-100 my-2 pointer p-3
      no-underline text-black
      `}
    onClick={(event) => {
      event.preventDefault();
      try {
        localStorage.setItem('queryID', hit.__queryID);
        localStorage.setItem('objectID', hit.objectID);
        insights('clickedObjectIDsAfterSearch', {
          eventName: 'Article Clicked',
        });
      } catch {
        // NOOP
      }
      navigate(hit.path);
    }}
  >
    <h4 className="text-secondary text-xl font-bold">
      <Highlight attribute="title" hit={hit} tagName="mark" />
    </h4>
    <h5 className="text-primary">
      <Highlight attribute="header" hit={hit} tagName="mark" />
    </h5>
    <Snippet attribute="content" hit={hit} tagName="mark" />
  </Link>
);

const PageHitWithInsights = connectHitInsights(aa)(PageHit);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHitWithInsights} />
  </Index>
);

const SearchResult = ({ indices, show, className }) => (
  <div className={clsx(className, show ? 'block' : 'hidden')}>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
);

export default SearchResult;
